
export default {
  props: {
    rate: {
      type: Number,
      default: 0,
    },
    comment: {
      type: String,
      default: '',
    },
    customer: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
}
