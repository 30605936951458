
export default {
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },
    color: {
      type: String,
      default: 'star',
    },
    size: {
      type: String,
      default: 'small',
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    starsArray() {
      return Array(5)
        .fill(this.color)
        .map((x, i) =>
          i < parseInt(this.value) ? this.color : 'star-inactive'
        )
    },
  },
}
