import { render, staticRenderFns } from "./HomepageReviewsCard.vue?vue&type=template&id=587f8ddc&scoped=true"
import script from "./HomepageReviewsCard.vue?vue&type=script&lang=js"
export * from "./HomepageReviewsCard.vue?vue&type=script&lang=js"
import style0 from "./HomepageReviewsCard.vue?vue&type=style&index=0&id=587f8ddc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "587f8ddc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RatingStars: require('/opt/atlassian/pipelines/agent/build/components/RatingStars.vue').default})
