
import HomepageReviewsCard from '@/containers/theme/HomepageReviews/HomepageReviewsCard.vue'
export default {
  components: {
    HomepageReviewsCard,
  },
  props: {},
  data() {
    return {
      reviews: [
        {
          rate: 5,
          title: 'Excellent online offering/experience',
          comment:
            'Amazing range of products (for all budgets), great comms, careful packaging, and fast delivery. I have made multiple orders in the past month. Very pleased!',
          customer: 'Mandy C',
        },
        {
          title: 'I have made a few online orders with…',
          rate: 5,
          comment:
            'I have made a few online orders with Yorkshire Trading Company. Always good quality items at great prices. Always well packed and promptly delivered',
          customer: 'Mrs Drinan',
        },
        {
          title: 'I order regularly from Yorkshire…',
          rate: 5,
          comment:
            'I order regularly from Yorkshire trading , and I can not fault them , I am always kept in form of when my order is sent out and when to expect it , which is always in a few days. Would recommend this company .',
          customer: 'Gibbs',
        },
      ],
    }
  },
  computed: {},
  methods: {
    goToLink() {
      window.open('https://uk.trustpilot.com/review/yorkshiretrading.com')
    },
  },
}
